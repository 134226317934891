/* eslint @next/next/no-img-element: 0 */

import { useCallback, useState } from "react"
import { uploadFileWithProgress, getVideoThumbnail } from '@/src/misc'
import useDeviceInfo from "@/src/useDeviceInfo"
import { useDropzone } from "react-dropzone"
import classNames from "classnames"
import ProgressBar from "./ProgressBar"
import { sendMessage } from "@/src/useMessage"



export default function FileUploader ({onUpload, multiple, methods, children, resize, accept}) {
  async function handleUploadComplete (files, thumbnailFile) {
    if (typeof onUpload === 'function') {
      await onUpload(files, thumbnailFile)
    } else {
      console.warn("The onUpload function wasn't passed to FileUploader")
    }
  }

  return <>
    <div className="file-uploader-ui">
      <FileDropZone onDone={handleUploadComplete} multiple={!!multiple} methods={methods} resize={!!resize} accept={accept}>
        { children }
      </FileDropZone>
    </div>
  </>
}


function FileDropZone({onDone, multiple, methods, children, resize, accept}) {

  const [uploadStatus, setUploadStatus] = useState('')
  const [uploadProgress, setUploadProgress] = useState(0)
  const [fileToPreview, setFileToPreview] = useState(null)
  const {touch, ios} = useDeviceInfo()

  const handleDrop = useCallback(async files => {
    if (!files.length) return

    setUploadStatus(`Preparing Upload...`)

    if (!multiple) {
      files = [files[0]]
    }

    let thumbnailFile = null
    let previewSrc = null

    if (files[0]) {
      try {
        const isVideo = files[0].type.startsWith('video')

        previewSrc = URL.createObjectURL(files[0])
        setFileToPreview({
          src: previewSrc,
          type: files[0].type,
        })

        // NOTE: canvas.toBlob method doesn't work correctly on iOS, so it cannot create a thumbnail
        if (isVideo && !ios) {
          const thumbnail = await getVideoThumbnail(files[0])

          if (thumbnail) {
            thumbnailFile = await uploadFileWithProgress(thumbnail)
          }
        }
      } catch (e) {
        let errorMessage = "File upload failed."
        if (e.message.startsWith('Sorry')) errorMessage = e.message
        sendMessage('error', errorMessage)
        setUploadStatus(``)
        setFileToPreview(null)
        return
      }
    }

    let cloudFiles = []

    const uploadOptions = {
      progress: percent => {
        setUploadStatus(`Uploading${files.length > 1 ? ` file ${cloudFiles.length + 1} of ${files.length}` : ''}...`)
        const progress = 100 / files.length * cloudFiles.length + percent / files.length
        setUploadProgress(progress)
      }
    }

    if (accept) {
      uploadOptions.type = 'any'
    }

    if (resize) uploadOptions.resize = true

    try {
      for (const file of files) {
        cloudFiles.push (await uploadFileWithProgress(file, uploadOptions))
      }
    } catch (e) {
      let errorMessage = "File upload failed."
      if (e.message.startsWith('Sorry')) errorMessage = e.message
      sendMessage('error', errorMessage)
      setUploadStatus(``)
      setFileToPreview(null)
      return
    }

    setUploadStatus(`Finalizing`)
    setUploadProgress(100)

    setTimeout(async () => {
      await onDone(cloudFiles, thumbnailFile)

      setUploadStatus(``)
      if (previewSrc) URL.revokeObjectURL(previewSrc)
      setFileToPreview(null)

      setTimeout(() => { setUploadProgress(0) }, 500)
    }, 600)

  }, [setUploadStatus, setUploadProgress, onDone, ios])

  const hasChildren = !!children

  const dropZoneOptions = {
    onDrop: handleDrop,
    accept: accept ? accept : 'image/*,video/*',

    // Disable click and keydown behavior if there are child components
    noClick: hasChildren,
    noKeyboard: hasChildren,
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    open,
    fileRejections,
  } = useDropzone(dropZoneOptions)

  if (methods) methods.open = () => {
    open()
  }

  return <>
    <div className={classNames("upload-ui content", {dragging: isDragActive, rejected: isDragReject})}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        { children ?
          children
        :
          <>
            <div className="drop-container clickable">
              { touch ?
                <div>
                  Tap here<br/>to upload your file.
                </div>
              :
                <div className="drop-area">
                  Drop your file{multiple ? 's' : ''} here, <br/>
                  or tap here to browse.
                </div>
              }
            </div>
          </>
        }

        { fileToPreview &&
          <div className="absolute-full z-1 overflow-hidden bg-white">
            { fileToPreview.type.startsWith('image') ?
              <img className="full-width" src={fileToPreview.src} alt="image preview" />
            : fileToPreview.type.startsWith('video') ?
              <video className="full-width" src={fileToPreview.src} />
            :
              null
            }
          </div>
        }
      </div>

      <div className="drop-indicator">
        <div className="not-allowed">
          Sorry. That type of file isn&apos;t allowed.
        </div>
      </div>

      { fileRejections.length > 0 &&
        <div className="red text-center">
          <b>Error</b>: { fileRejections[0].errors[0].message }
        </div>
      }

      <div className={classNames("status padded", {visible: !!uploadStatus})}>
        { uploadStatus }<br/>
        <div style={{marginTop: '0.5rem'}}>
          <ProgressBar value={uploadProgress}/>
        </div>
      </div>
    </div>

    <style jsx>{`
      .upload-ui {
        position: relative;
      }
      .drop-indicator {
        background-color: #3499cc66;
        opacity: 0;
        transition: opacity 200ms;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .not-allowed {
        transition: opacity 200ms;
        opacity: 0;
        color: darkred;
        font-weight: bold;
        font-size: 1.3rem;
        margin: 1rem;
        text-align: center;
      }
      .dragging .drop-indicator {
        opacity: 1;
      }
      .rejected .drop-indicator {
        background-color: #FF0000AA;
        cursor: not-allowed;
      }
      .rejected .not-allowed {
        opacity: 1;
      }
      .drop-container {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 2px dashed #ccc;
        color: #999;
        cursor: pointer;
        margin: 0.5rem 0;
        position: relative;
        min-width: 200px;
      }
      .status {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        transition: opacity 250ms;
        pointer-events: none;
        text-align: center;
        z-index: 1;
      }
      .drop-container {
        background-color: #f3f3f3;
      }
      .status {
        background-color: #f3f3f3cc;
      }
      .visible {
        opacity: 1;
      }
      .drop-area {
        padding: 2rem 0.5rem;
        width : 100%;
      }
    `}</style>
  </>
}
